<template>
  <div find-progress class="contents" v-if="structure.template">
    <ValidationObserver tag="div" class="field-holder" ref="validator" v-slot="{ invalid }">
      <div class="content">
        <FpTitle v-if="showTitle" :title="structure.title" :back="structure.back" app-name="findId" />
        <component :is="structure.template" :model-info="modelInfo" :structure="structure" :error-msg="structure.errorMsg" @submit="submit" @clear-error="clearError" />
      </div>
      <div v-if="structure.btnName" class="bottom">
        <ProgressButton :button-name="structure.btnName" :progress="isProgress" @click="submit" :disabled="invalid || structure.error" />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FpTitle from '@/views/components/pages/forgot-password/FpTitle.vue';
import { brandIds, displayBrandNames, siteIds } from '@/constants/base/siteMap';
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'FindProgress',
  components: { FpTitle, ProgressButton, FontIcon },
  data() {
    return {
      isProgress: false,
      captcha: null,
      modelInfo: { VerificationCode: null, Password: null, Email : null, CountryCode : null, MobileNumber :null, UserName : null, PhoneCountryCode : null, },
      structure: { title: null, desc: null, btnName: '', back: false, success: false, template: null, error: false, errorMsg: null, hasButton: true, completeReset: true, resendInitTime: 0, methodType: 'Email' },
    };
  },
  computed: {
    brand: state('env', 'brand'),
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    showTitle(){
      const name = this.$route.name;
      return !['FiEmailGuide', 'FiPasswordGuide'].includes(name);
    },
    isMBP() {
      return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site);
    },
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    },
  },
  watch: {
    $route: 'update',
  },
  methods: {
    async loadCaptcha() {
      if (!this.$findId.captchaAction) return;
      this.captcha = {};
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(this.$findId.captchaAction);
      // if (this.$findId.captchaAction) this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(this.$findId.captchaAction);
    },
    async submit() {
      if(this.$route.name === 'FiEmailGuide'){
        await this.routeName('FiCreate');
        return;
      }
      if(this.$route.name === 'FiPasswordGuide'){
        await this.routeName('FiConfirmPw');
        return;
      }
      this.isProgress = true;
      await this.loadCaptcha();
      const r = await this.$findId.confirm({ ...this.modelInfo, ...this.captcha });
      this.isProgress = false;
      this.structure.resendInitTime = 0;
      if (r.error) {
        switch (r.key) {
          case 'limit': {
            this.structure.resendInitTime = +r.value;
            break;
          }
          case apiErrorCode.ACCOUNT_NOT_FOUND: {
            this.structure.error = true;
            const method = this.structure.methodType === 'Email' ? this.$t('emailAddress') : this.$t('mobileNumber');
            if(this.$route.name === 'FiCredential'){
              let credentialMethod = method;
              if(this.structure.methodType === 'Email'){
                const name = this.modelInfo.Email;
                if(name && !name.includes('@')) credentialMethod = this.$t('username');
              }

              this.structure.errorMsg = this.$t('FIND_ACCOUNT_NOT_FOUND', { method: credentialMethod, brand: this.displayBrandName });
            }else {
              this.structure.errorMsg = this.$t(r.key, { method: method, brand: this.displayBrandName });
            }

            return;
          }
          default : {
            this.structure.error = true;
            this.structure.errorMsg = this.$t(r.key, { brand: this.displayBrandName, csEmail : this.csEmail });
            return;
          }
        }
      } else if (r.FindStep) {
        if (r.FindKey) this.$findId.updateSiteMap(r.FindKey);
        // const step = { Complete: 'FiComplete', EmailVerify: 'FiCreate', SetPassword: 'FiConfirmPw' };
        const step = { Complete: 'FiComplete', EmailVerify: 'FiEmailGuide', SetPassword: 'FiPasswordGuide' };

        this.modelInfo = { ...this.modelInfo, ...r };
        await this.routeName(step[r.FindStep]);
        return;
      }

      await this.routeName(this.$findId.next);
    },
    update() {
      if (['FiCredential', 'FiEmail', 'FiMobile'].includes(this.$route.name)) this.$store.commit('query/setFindKey', null);
      this.$findId.changeStep(this.$route.name);

      const now = this.$findId.currentPageModel;
      this.structure.title = now.title;
      this.structure.subTitle = now.subTitle;
      this.structure.desc = now.desc;
      this.structure.btnName = now.btnName;
      this.structure.template = now.component;
      this.structure.back = now.back;
      this.clearError();
    },
    clearError() {
      this.structure.errorMsg = null;
      this.structure.error = false;
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>